// import axios from 'axios'
import Vue from 'vue';
import VueLocalStorage from 'vue-localstorage';
import { HTTP } from '@/api/http_utils'

// import router from '@/router/index.js'

Vue.use(VueLocalStorage);

export default {
    namespaced: true,
    state: {
        //
    },
    getters: {
        //
    },
    mutations: {
        //
    },
    actions: {
        getList ({ commit }, payload) {
            return new Promise((resolve, reject) => {
                HTTP.get(`/products?page=${payload['page']}&per_page=${payload['perPage']}` + payload['filters']).then(response => {
                    resolve(response)
                }).catch(error => {
                    reject(error)
                })
            })
        },
        get ({ commit }, Id) {
            return new Promise((resolve, reject) => {
                HTTP.get('/products/' + Id).then(response => {
                    resolve(response)
                }).catch(error => {
                    reject(error)
                })
            })
        },
        create ({ commit }, payload) {
            return new Promise((resolve, reject) => {
                HTTP.post('/products', payload).then(response => {
                    resolve(response)
                }).catch(error => {
                    reject(error)
                })
            })
        },
        update ({ commit }, [Id, payload]) {
            return new Promise((resolve, reject) => {
                HTTP.patch('/products/' + Id, payload).then(response => {
                    resolve(response)
                }).catch(error => {
                    reject(error)
                })
            })
        },
        attach ({ commit }, [Id, payload]) {
            return new Promise((resolve, reject) => {
                HTTP.post('/products/' + Id + '/attach', payload).then(response => {
                    resolve(response)
                }).catch(error => {
                    reject(error)
                })
            })
        },
        import ({ commit }, payload) {
            return new Promise((resolve, reject) => {
                HTTP.post('/products/import', payload).then(response => {
                    resolve(response)
                }).catch(error => {
                    reject(error)
                })
            })
        },
        search ({ commit }, [searchString, add_query = null]) {
            return new Promise((resolve, reject) => {
                let search_query = `/products?name=${searchString}&add_data=true`
                if (add_query) {
                    search_query = `/products?name=${searchString}&add_data=true` + add_query
                }

                HTTP.get(search_query).then((response) => {
                    resolve(response)
                }).catch(error => {
                    reject(error)
                })
            })
        },
        // mainImage ({ commit }, [Id, payload]) {
        //     return new Promise((resolve, reject) => {
        //         HTTP.post('/products/' + Id + '/main_image', payload).then(response => {
        //             resolve(response)
        //         }).catch(error => {
        //             reject(error)
        //         })
        //     })
        // },
        delete ({ commit }, Id) {
            return new Promise((resolve, reject) => {
                HTTP.delete('/products/' + Id).then(response => {
                    resolve(response)
                }).catch(error => {
                    reject(error)
                })
            })
        },
        batchDelete ({ commit }, Ids) {
            return new Promise((resolve, reject) => {
                HTTP.post('/products/batch-delete/' + Ids).then(response => {
                    resolve(response)
                }).catch(error => {
                    reject(error)
                })
            })
        }
    },
  };