// import axios from 'axios'
import Vue from 'vue';
import VueLocalStorage from 'vue-localstorage';
import { HTTP } from '@/api/http_utils'

// import router from '@/router/index.js'

Vue.use(VueLocalStorage);

export default {
    namespaced: true,
    state: {
        //
    },
    getters: {
        //
    },
    mutations: {
        //
    },
    actions: {
        getList ({ commit }, payload) {
            return new Promise((resolve, reject) => {
                HTTP.get(`/categories?page=${payload['page']}&per_page=${payload['perPage']}` + payload['filters']).then(response => {
                    resolve(response)
                }).catch(error => {
                    reject(error)
                })
            })
        },
        getAll ({ commit }) {
            return new Promise((resolve, reject) => {
                HTTP.get('/categories').then(response => {
                    resolve(response)
                }).catch(error => {
                    reject(error)
                })
            })
        },
        getCategory ({ commit }, data) {
            return new Promise((resolve, reject) => {
                HTTP.get('/categories/' + data['categoryId']).then(response => {
                    resolve(response)
                }).catch(error => {
                    reject(error)
                })
            })
        },
        createCategory ({ commit }, payload) {
            return new Promise((resolve, reject) => {
                HTTP.post('/categories', payload).then(response => {
                    resolve(response)
                }).catch(error => {
                    reject(error)
                })
            })
        },
        updateCategory ({ commit }, [categoryId, payload]) {
            return new Promise((resolve, reject) => {
                HTTP.patch('/categories/' + categoryId, payload).then(response => {
                    resolve(response)
                }).catch(error => {
                    reject(error)
                })
            })
        },
        deleteCategory ({ commit }, categoryId) {
            return new Promise((resolve, reject) => {
                HTTP.delete('/categories/' + categoryId).then(response => {
                    resolve(response)
                }).catch(error => {
                    reject(error)
                })
            })
        },
        attach ({ commit }, [Id, payload]) {
            return new Promise((resolve, reject) => {
                HTTP.post('/categories/' + Id + '/attach', payload).then(response => {
                    resolve(response)
                }).catch(error => {
                    reject(error)
                })
            })
        },
        batchDelete ({ commit }, Ids) {
            return new Promise((resolve, reject) => {
                HTTP.post('/categories/batch-delete/' + Ids).then(response => {
                    resolve(response)
                }).catch(error => {
                    reject(error)
                })
            })
        }
    },
};