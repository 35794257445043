import uk from "vee-validate/dist/locale/uk.json";

export default {
    fields: {
        'email': "E-mail",
        'Email': 'Электронное письмо',
        'password': "Пароль",
        'title': 'Назва',
        'Vendor code': 'Код постачальника',
        'name uk': 'Назва',
        'url': 'URL',
        'Description': 'Опис',
        'country': 'Країна',
        'city': 'Місто',
        'address': 'Адреса',
        'phone': 'Номер телефону',
        'first_name': 'Ім`я',
        'last_name': 'По батькові',
        'subtotal': 'Вартість замовлення',
        'discount_type': 'Тип знижки',
        'fields.Catalog': 'Каталог'
    },
    validation: uk.messages,
    'List': 'Перелік',
    'Filter already added': 'Фільтр уже додано',
    'ContactsUs': 'Зв\'язок з нами',
    'contacts': 'контакти',
    'Faqs': 'Питання та відповіді',
    'Categories': 'Категорії',
    'Products': 'Товари',
    'products': 'товары',
    'homepage': 'Головна',
    'Filter': 'фильтр',
    'Create': 'Створити',
    'Type to search': 'Введіть для пошуку',
    'Add filter': 'Додати фільтр',
    'Search': 'Пошук',
    'View': 'Перегляд',
    'view': 'перегляд',
    'Edit': 'Редагувати',
    'Remove': 'Видалити',
    'Main settings': 'Основні налаштування',
    'Additional settings': 'Додаткові налаштування',
    'Russian': 'Русский',
    'Ukrainian': 'Украинский',
    'Photo': 'Зображення',
    'validation.decimal': 'Поле має бути числом',
    'Priority': 'Пріоритет',
    'Submit': 'Відправити',
    'Name': 'Назва',
    'Logout': 'Вихід',
    'Catalogs': 'Каталоги',
    'Catalog': 'Каталог',
    'Description': 'Опис',
    'edit': 'редагувати',
    'catalogs': 'каталоги',
    'Filters': 'Фільтри',
    'filters': 'фільтри',
    'Is active': 'Активний',
    'Is Active': 'Активний',
    'categories': 'категорії',
    'Text': 'Текст',
    'Number': 'Число',
    'Price': 'Ціна',
    'Category': 'Категорія',
    'Keywords': 'Ключові слова',
    'Vendor code': 'Код постачальника',
    'Static pages': 'Статичні сторінки',
    'Settings': 'Налаштування',
    'Extension image required': 'Потрібне зображення у розширенні',
    'Add to promotional': 'Додати обраний товар до акції',
    'Promotionals': 'Акції',
    'Blog': 'Блог',
    'Brands': 'Бренди',
    'Services': 'Сервіси',
    'services': 'сервіси',
    'Country': 'Країна',
    'Brand': 'Бренд',
    'Created at': 'Створено',
    'brands': 'бренди',
    'Title': 'Назва',
    'date_supp': 'Дата звернення',
    'Select catalog': 'Вибрати каталог',
    'Subject': 'Тема',
    'Username': 'Ім\'я користувача',
    'Phone': 'Номер телефону',
    'sup_contacts': 'Обращения в поддержку',
    'faqs_request': 'Запросы',
    'Dashboard': 'Приладова панель',
    'Users': 'Користувачі',
    'faqs': 'Питання та відповіді',
    'Requests list': 'Список звернень',
    'Email': 'Email',
    'Warning': 'Попередження',
    'Are you sure you want to delete the request?': 'Ви впевнені, що хочете видалити запит?',
    'Cancel': 'Скасувати',
    'OK': 'ОК',
    'Delete completed': 'Успішно видалено',
    'Delete canceled': 'Видалення скасовано',
    'Oops, try again later.': 'Упс, спробуйте пізніше.',
    'faq categories': 'Питання та відповіді - категорії',
    'faq requests': 'Запитання і відповіді - запити користувачів',
    'Frequently asked Questions': 'Поширені запитання',
    'faq_category_delete_msg': 'Ви впевнені, що хочете видалити категорію? Запитання та відповіді які належать до цієї категорії будуть видалені!!!',
    'catalog_delete_msg': 'Впевнені, що хочете видалити каталог? Категорії, товари, що належать до даного каталогу, будуть видалені!!!',
    'brand_delete_msg': 'Впевнені, що бажаєте видалити бренд? Товари, які використовували даний бренд, отримають порожнє значення!!!',
    'filter_delete_msg': 'Впевнені, що хочете видалити фільтр? Категорії, товари втратять можливість використовувати даний фільтр!!!',
    'Posts list': 'Список постів',
    'posts list': 'список постів',
    'Publicated at': 'Дата публікації',
    'Type': 'Тип',
    'Active': 'Активні',
    'Inactive': 'Не активні',
    'Reset': 'Скинути',
    'Select': 'Вибрати',
    'Not selected': 'Не вибраний',
    'email_verified': 'Email підтверджений',
    'is_admin': 'Адміністратор',
    'This email already used': 'Цей email вже використовується іншим користувачем',
    'Please provide promo code.': 'Будь ласка, надайте промокод.',
    'Vendor Code': 'Код постачальника',
    'Unique ID': 'Унікальний ідентифікатор',
    'Image': 'Зображення',
    'Images': 'Зображення',
    'Orders': 'Замовлення',
    'orders': 'замовлення',
    'New': 'Новий',
    'discount_fixed': 'Фіксована скидка',
    'discount_percentage': 'Процент від вартості',
    'measure_square_meter' : 'кв.м',
    'measure_thing': 'шт.',
    'measure_roll': 'рулон',
    'measure_packaging': 'упаковка',
    'Additional information': 'Додаткова інформація',
    'Cancelled': 'Скасовано',
    'Delivered': 'Доставлено',
    'In Progress': 'У ході виконання',
    'Canceled': 'Скасовано',
    'Address': 'Адреса',
    'Successfully completed': 'Успішно виконано',
    'User comment': 'Коментар користувача',
    'Manager comment': 'Коментар менеджера',
    'Status': 'Статус замовлення',
    'Order number': 'Номер замовлення',
    'Order cost ₴': 'Вартість замовлення',
    'local_shipping': 'Самовивіз',
    'courier_shipping': 'Кур\'єрська доставка',
    'Sending': 'Надіслано',
    'Local pickup from store': 'Самовивіз із магазину',
    'Courier delivery': 'Кур\'єрська доставка',
    'First name': 'Ім\'я',
    'Last name': 'Прізвище',
    'Shipping type': 'Вид доставки',
    'City': 'Місто',
    'Our stores': 'Наші магазини',
    'social_sidebar': 'Соц. мережі',
    'delete_service_msg': 'Впевнені, що бажаєте видалити сервіс?',
    'Success': 'Успішно',
    'Saved / Modified Successfully': 'Успішно збережено/змінено',
    'faq_delete_msg': 'Ви впевнені, що хочете видалити поточний запис?',
    'Content': 'Контент',
    'Icon': 'Значок',
    'delete_product_msg': 'Впевнені, що бажаєте видалити товар? Після видалення всі пов\'язані з ним записи будуть видалені!',
    'Message': 'Повідомлення',
    'Is Admin': 'Адміністратор',
    'User': 'Користувач',
    'Are you sure you want to delete the current object?': 'Ви впевнені, що хочете видалити поточний об\'єкт?',
    'Are you sure you want to delete the category?': 'Ви впевнені, що хочете видалити поточну категорію?',
    'users': 'користувачі',
    'multiple_select': 'дозволити множинний вибір',
    'filter_items': 'Найменування/Величина',
    'Add': 'Додати',
    'static pages': 'статичні сторінки',
    'Page': 'Сторінка',
    'Page type': 'Тип сторінки',
    'Quantity': 'Кількість',
    'Processed': 'Оброблений',
    'Upload image': 'Завантажити зображення',
    'collections': 'колекції',
    'Collections': 'Колекції',
    'Collection': 'Колекції',
    'Collection parent': 'Батьківська колекції',
    'Photo for gallery': 'Фото для галереї',
    'Search products': 'Пошук товару',
    'Please enter a keyword': 'Введіть потрібне слово',
    'Collection products': 'Товари, що перебувають у колекції',
    'delete_collection_product_msg': 'Ви дійсно бажаєте видалити цей товар з колекції?',
    'Without price': 'Без ціни',
    'Availability': 'В наявності',
    'Measure': 'Одиниці виміру',
    'Start date': 'Початок',
    'End date': 'Кінець',
    'Label': 'Ярлик',
    'Select discount type': 'Виберіть тип знижки',
    'np_default': 'НП на відділення',
    'np_courier': 'НП кур\'єр',
    'Period': 'Період',
    'Discount type': 'Тип знижки',
    'Discount': 'Знижка',
    'Promotional products': 'Товари, що беруть участь в акції',
    'Is collection': 'Це колекція',
    'Import was successful': 'Імпорт пройшов успішно',
    'Preview': 'Перегляд',
    'Import file': 'Імпорт файлу',
    'Category ID': 'ID категорії',
    'products import': 'імпорт товарів',
    'promotional_percentage': 'Відсоток від вартості',
    'promotional_fixed': 'Фіксована',
    'Products import': 'Імпорт товарів',
    'Import': 'Імпорт',
    'Fill in all required fields': 'Деякі з полів не вірно заповнені, перевірте та повторіть спробу!',
    'batch_delete_product_msg': 'Впевнені, що хочете видалити вибрані товари? Після видалення товарів всі пов\'язані з ними записи будуть видалені!',
    'Slider': 'Слайдер',
    'Updated at': 'Дата зміни',
    'Is admin': 'Адміністратор',
    'Choose xlsx file': 'Виберіть файл xlsx',
    'Batch delete': 'Пакетне видалення',
    'sliders': 'Слайдер',
    'This value must be url': 'Це поле має бути посиланням',
    'This value must be phone': 'Це поле має бути номером телефону',
    'Start time': 'Дата початку',
    'End time': 'Дата закінчення',
    'promotionals': 'акції',
    'socials': 'соціальні мережі',
    'our stores': 'наші магазини',
    'store': 'магазин',
    'Add keywords': 'Додати ключі',
    'copy': 'копіювати',
    'Error': 'Помилка',
    'Exceeding the allowed number of fields for import.': 'Перевищення допустимої кількості полів імпорту.',
    'Are you sure you want to delete?': 'Ви впевнені, що бажаєте видалити?',
    'Price from': 'Ціна від',
    'Successfully paid': 'Успішно оплачено',
    'Payment type': 'Вид оплати',
    'Payment status': 'Статус оплати',
    'on_delivery': 'При отриманні',
    'Online': 'Онлайн',
    'ID': 'ID',
    'Guarantee': 'Гарантія',
    'Terms of Use': 'Умови використання',
    'Privacy Policy': 'Політика конфіденційності',
    'Payment and delivery': 'Оплата і доставка',
    'Unit': 'Значення',
    'Socials': 'Соц. Мережі',
    'List of appeals': 'Список звернень',
    'Other settings': 'Інші налаштування',
    'Store name': 'Назва магазину',
    'Edit settings': 'Редагувати налаштування',
    'Create settings': 'Створити налаштування',
    'Phones': 'Телефони',
    'Work times': 'Робочий час',
    'Email-s': 'Електронні адреси',
    'Instagram URL': 'URL-адреса Instagram',
    'Are you sure you want to delete the current filter?': 'Ви впевнені, що хочете видалити фільтр?'
    // 'Description': 'Описание'

};