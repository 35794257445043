<template>
  <div id="app" v-loading.fullscreen.lock="fullscreenLoading">
      <HeaderComponent v-if="isAuth" />
      <div class="container-fluid">
        <div class="row">
          <Sidebar v-if="isAuth" />
          <main role="main" :class="isAuth ? 'col-md-9 ml-sm-auto col-lg-10 px-4' : 'col-md-12 ml-sm-auto col-lg-12 px-4'">
            <router-view/>
          </main>
        </div>
      </div>
  </div>
</template>

<script>
import 'bootstrap'

import HeaderComponent from '@/components/Header'
import Sidebar from '@/components/Sidebar'
import { EventBus } from '@/plugins/event-bus.js';

export default {
  name: 'App',
  data () {
    return {
      fullscreenLoading: false,
      openLoader: false,
    }
  },
  components: { HeaderComponent, Sidebar },
  created () {
    EventBus.$on('loader', this.loaderData)
  },
  watch: {
    openLoader: function (val) {
      // if (val === false) {
      //   setTimeout(() => {
      //     this.fullscreenLoading = val
      //   }, 1200)
      // } else {
      //   this.fullscreenLoading = val
      // }
      this.fullscreenLoading = val
    },
  },
  computed: {
    isAuth () {
      return this.$store.getters['auth/isAuthenticated']
    }
  },
  methods: {
    loaderData (val) {
      this.openLoader = val
    }
  }
}
</script>
<style lang="scss">
@import 'bootstrap';
// @import "~bootstrap/dist/css/bootstrap.min.css";
// @import "~bootstrap/dist/css/bootstrap.css";
// #app {
//   font-family: Avenir, Helvetica, Arial, sans-serif;
//   -webkit-font-smoothing: antialiased;
//   -moz-osx-font-smoothing: grayscale;
//   text-align: center;
//   color: #2c3e50;
// }

body {
  font-size: .875rem;
}

.feather {
  width: 16px;
  height: 16px;
  vertical-align: text-bottom;
}

/*
 * Sidebar
 */

.sidebar {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 100; /* Behind the navbar */
  padding: 48px 0 0; /* Height of navbar */
  box-shadow: inset -1px 0 0 rgba(0, 0, 0, .1);
}

.sidebar-sticky {
  position: relative;
  top: 0;
  height: calc(100vh - 48px);
  padding-top: .5rem;
  overflow-x: hidden;
  overflow-y: auto; /* Scrollable contents if viewport is shorter than content. */
}

@supports ((position: -webkit-sticky) or (position: sticky)) {
  .sidebar-sticky {
    position: -webkit-sticky;
    position: sticky;
  }
}

.sidebar .nav-link {
  font-weight: 500;
  color: #333;
}

.sidebar .nav-link .feather {
  margin-right: 4px;
  color: #999;
}

.sidebar .nav-link.active {
  color: #007bff;
}

.sidebar .nav-link:hover .feather,
.sidebar .nav-link.active .feather {
  color: inherit;
}

.sidebar-heading {
  font-size: .75rem;
  text-transform: uppercase;
}

/*
 * Content
 */

[role="main"] {
  padding-top: 48px; /* Space for fixed navbar */
}

/*
 * Navbar
 */

.navbar-brand {
  padding-top: .75rem;
  padding-bottom: .75rem;
  font-size: 1rem;
  background-color: rgba(0, 0, 0, .25);
  box-shadow: inset -1px 0 0 rgba(0, 0, 0, .25);
}

.navbar .form-control {
  padding: .75rem 1rem;
  border-width: 0;
  border-radius: 0;
}

.form-control-dark {
  color: #fff;
  background-color: rgba(255, 255, 255, .1);
  border-color: rgba(255, 255, 255, .1);
}

.form-control-dark:focus {
  border-color: transparent;
  box-shadow: 0 0 0 3px rgba(255, 255, 255, .25);
}

.el-table_1_column_1.el-table-column--selection.is-leaf {
  padding-left: 5px !important;
}
// #nav {
//   padding: 30px;

//   a {
//     font-weight: bold;
//     color: #2c3e50;

//     &.router-link-exact-active {
//       color: #42b983;
//     }
//   }
// }
</style>
