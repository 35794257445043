// import axios from 'axios'
import Vue from 'vue';
import VueLocalStorage from 'vue-localstorage';
import { HTTP } from '@/api/http_utils'

Vue.use(VueLocalStorage);

export default {
    namespaced: true,
    state: {
        //
    },
    getters: {
        //
    },
    mutations: {
        //
    },
    actions: {
        getList ({ commit }, data) {
            return new Promise((resolve, reject) => {
                HTTP.get('/blog-posts').then(response => {
                    resolve(response)
                }).catch(error => {
                    reject(error)
                })
            })
        },
        get ({ commit }, Id) {
            return new Promise((resolve, reject) => {
                HTTP.get('/blog-posts/' + Id).then(response => {
                    resolve(response)
                }).catch(error => {
                    reject(error)
                })
            })
        },
        create ({ commit }, payload) {
            return new Promise((resolve, reject) => {
                HTTP.post('/blog-posts', payload).then(response => {
                    resolve(response)
                }).catch(error => {
                    reject(error)
                })
            })
        },
        update ({ commit }, [Id, payload]) {
            return new Promise((resolve, reject) => {
                HTTP.patch('/blog-posts/' + Id, payload).then(response => {
                    resolve(response)
                }).catch(error => {
                    reject(error)
                })
            })
        },
        delete ({ commit }, Id) {
            return new Promise((resolve, reject) => {
                HTTP.delete('/blog-posts/' + Id).then(response => {
                    resolve(response)
                }).catch(error => {
                    reject(error)
                })
            })
        }
    },
  };