// import axios from 'axios'
import Vue from "vue";
import VueLocalStorage from "vue-localstorage";
import { HTTP, axiosBasic } from "@/api/http_utils";
import axios from "axios";
import router from "@/router/index.js";

Vue.use(VueLocalStorage);

export default {
  namespaced: true,
  state: {
    apiKey: Vue.localStorage.get("api_key"),
  },
  getters: {
    isAuthenticated: (state) => !!state.apiKey,
    apiKey: (state) => state.apiKey,
  },
  mutations: {
    AUTH(state, authData) {
      console.log('SetAuth', authData)
      if (authData.apiKey) {
        state.apiKey = authData.apiKey;
        Vue.localStorage.set("api_key", authData.apiKey);

        state.tokenType = authData.tokenType;
        Vue.localStorage.set("token_type", authData.tokenType);
      } else {
        Vue.localStorage.remove("api_key");
        Vue.localStorage.remove("token_type");
        state.isAuthenticated = false;
      }
      // Vue.localStorage.set('api_key', authData.apiKey);
      // state.apiKey = authData.apiKey;
      // state.tokenType = authData.tokenType;
    },
    AUTH_LOGOUT(state) {
      Vue.localStorage.remove("api_key");
      Vue.localStorage.remove("token_type");
      state.isAuthenticated = false;
    },
  },
  actions: {
    registerUser({ commit }, registrationData) {
      return new Promise((resolve, reject) => {
        HTTP.post("/register", registrationData)
          .then(() => {
            let clientData = {
              client_id: process.env.VUE_APP_OAUTH_CLIENT_ID,
              client_secret: process.env.VUE_APP_OAUTH_CLIENT_SECRET,
              grant_type: "password",
              scope: "*",
            };
            HTTP.post("oauth/token", {
              username: registrationData.email,
              password: registrationData.password,
              ...clientData,
            })
              .then((response) => {
                axios.defaults.headers.common["Content-Type"] =
                  "application/json";
                if (response) {
                  axios.defaults.headers.common["Authorization"] =
                    response["token_type"] + " " + response["access_token"];
                  axiosBasic.defaults.headers.common["Authorization"] =
                    response["token_type"] + " " + response["access_token"];
                  // axiosCachedImage.defaults.headers.common['Authorization'] = response['token_type'] + ' ' + response['access_token']
                }
                commit("AUTH", {
                  apiKey: response["access_token"],
                  tokenType: response["token_type"],
                });
                resolve(response);
              })
              .catch((error) => {
                this.dispatch("getErrors", error.response.data).then();
                commit("AUTH_LOGOUT");
                reject(error);
              });
          })
          .catch((error) => {
            this.dispatch("getErrors", error.response.data).then();
            commit("AUTH_LOGOUT");
            reject(error);
          });
      });
    },
    authUser({ commit }, AuthData) {
      return new Promise((resolve, reject) => {
        const clientData = {
          client_id: process.env.VUE_APP_OAUTH_CLIENT_ID,
          client_secret: process.env.VUE_APP_OAUTH_CLIENT_SECRET,
          grant_type: AuthData["grant_type"],
          scope: "*",
        };

        axios.defaults.baseURL = process.env.VUE_APP_ROOT_SITE_API

        axios.post("oauth/token", { ...AuthData, ...clientData })
          .then((response) => {

            if (response.data) {
              response = response.data
            }

            console.log(1)
            axios.defaults.headers.common["Content-Type"] = "application/json";
            axios.defaults.baseURL = process.env.VUE_APP_ROOT_API

            if (response) {
              console.log(2)
              axios.defaults.headers.common["Authorization"] =
                response["token_type"] + " " + response["access_token"];
              axiosBasic.defaults.headers.common["Authorization"] =
                response["token_type"] + " " + response["access_token"];
              // axiosCachedImage.defaults.headers.common['Authorization'] = response['token_type'] + ' ' + response['access_token']
            }
            console.log(3)
            commit("AUTH", {
              apiKey: response["access_token"],
              tokenType: response["token_type"],
            });
            resolve(response);
          })
          .catch((error) => {
            console.log(4)
            axios.defaults.baseURL = process.env.VUE_APP_ROOT_API
            let err = { errors: { apiErrors: [error.response.data] } };
            this.dispatch("getErrors", err).then();
            commit("AUTH_LOGOUT");
            reject(error);
          });
      });
    },
    logOut({ commit }) {
      Vue.localStorage.remove();
      commit("AUTH_LOGOUT");
      router.push({ name: "home" });
      location.reload();
    }
  }
};
