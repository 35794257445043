// import axios from 'axios'
import Vue from 'vue';
import VueLocalStorage from 'vue-localstorage';
import { HTTP } from '@/api/http_utils'

// import router from '@/router/index.js'

Vue.use(VueLocalStorage);

export default {
    namespaced: true,
    state: {
        //
    },
    getters: {
        //
    },
    mutations: {
        //
    },
    actions: {
        getList ({ commit }, data) {
            return new Promise((resolve, reject) => {
                HTTP.get('/filters').then(response => {
                    resolve(response)
                }).catch(error => {
                    reject(error)
                })
            })
        },
        getFilter ({ commit }, data) {
            console.log(data)
            return new Promise((resolve, reject) => {
                HTTP.get('/filters/' + data['filterId']).then(response => {
                    resolve(response)
                }).catch(error => {
                    reject(error)
                })
            })
        },
        createFilter ({ commit }, payload) {
            return new Promise((resolve, reject) => {
                HTTP.post('/filters', payload).then(response => {
                    resolve(response)
                }).catch(error => {
                    reject(error)
                })
            })
        },
        updateFilter ({ commit }, [filterId, payload]) {
            return new Promise((resolve, reject) => {
                HTTP.patch('/filters/' + filterId, payload).then(response => {
                    resolve(response)
                }).catch(error => {
                    reject(error)
                })
            })
        },
        deleteFilter ({ commit }, filterId) {
            return new Promise((resolve, reject) => {
                HTTP.delete('/filters/' + filterId).then(response => {
                    resolve(response)
                }).catch(error => {
                    reject(error)
                })
            })
        },
        batchDelete ({ commit }, Ids) {
            return new Promise((resolve, reject) => {
                HTTP.post('/filters/batch-delete/' + Ids).then(response => {
                    resolve(response)
                }).catch(error => {
                    reject(error)
                })
            })
        }
    },
  };