// import axios from 'axios'
import Vue from 'vue';
import VueLocalStorage from 'vue-localstorage';
import { HTTP } from '@/api/http_utils'

// import router from '@/router/index.js'

Vue.use(VueLocalStorage);

export default {
    namespaced: true,
    state: {
        //
    },
    getters: {
        //
    },
    mutations: {
        //
    },
    actions: {
        getSettings ({ commit }, data) {
            return new Promise((resolve, reject) => {
                HTTP.get('/settings').then(response => {
                    resolve(response)
                }).catch(error => {
                    reject(error)
                })
            })
        },
        setSettings ({ commit }, payload) {
            return new Promise((resolve, reject) => {
                HTTP.post('/settings', payload).then(response => {
                    resolve(response)
                }).catch(error => {
                    reject(error)
                })
            })
        },
        updateSettings ({ commit }, [settingsId, payload]) {
            return new Promise((resolve, reject) => {
                HTTP.patch(`/settings/${settingsId}`, payload).then(response => {
                    resolve(response)
                }).catch(error => {
                    reject(error)
                })
            })
        },
        getSocials ({ commit }, data) {
            return new Promise((resolve, reject) => {
                HTTP.get('/socials').then(response => {
                    resolve(response)
                }).catch(error => {
                    reject(error)
                })
            })
        },
        updateSocials ({ commit }, payload) {
            return new Promise((resolve, reject) => {
                HTTP.patch('/socials', payload).then(response => {
                    resolve(response)
                }).catch(error => {
                    reject(error)
                })
            })
        },
        getContacts ({ commit }, Id) {
            return new Promise((resolve, reject) => {
                HTTP.get('/static-pages/' + Id).then(response => {
                    resolve(response)
                }).catch(error => {
                    reject(error)
                })
            })
        },
        updateContacts ({ commit }, payload) {
            return new Promise((resolve, reject) => {
                HTTP.patch('/static-pages/', payload).then(response => {
                    resolve(response)
                }).catch(error => {
                    reject(error)
                })
            })
        },
    },
};