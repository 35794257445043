// import Home from '@/views/Home.vue'
// import RouteLanguage from '@/components/RouteLanguage.vue'
// import { i18n } from '../plugins/i18n.js';
// import store from '../store';

export default [
    // CATALOG ROUTES
    {
        path: '/catalogs',
        name: 'CatalogsList',
        component: () => import( '@/components/Catalog/Catalogs'),
    },
    {
        path: '/catalogs/create',
        name: 'CatalogCreate',
        component: () => import( '@/components/Catalog/CatalogEdit'),

    },
    // USERS ROUTES
    {
        path: '/users',
        name: 'UsersList',
        component: () => import( '@/components/User/Users'),
    },
    // {
    //     path: '/users',
    //     name: 'UserCreate',
    //     component: () => import( '@/components/User/UserEdit'),

    // },
    {
        path: '/users/:userId',
        component: () => import( '@/components/ChildRouter'),
        children: [
            {
                path: 'edit',
                name: 'UserEdit',
                component: () => import( '@/components/User/UserEdit'),
            }
        ]

    },
    {
        path: '/catalogs/:catalogId',
        component: () => import( '@/components/ChildRouter'),
        children: [
            {
                path: 'edit',
                name: 'CatalogEdit',
                component: () => import( '@/components/Catalog/CatalogEdit'),
            }
        ]
    },
    // CATALOG-CATEGORIES ROUTES
    {
        path: '/categories',
        name: 'CategoriesList',
        component: () => import( '@/components/Category/Categories'),
    },
    {
        path: '/categories/create',
        name: 'CategoryCreate',
        component: () => import( '@/components/Category/CategoryEdit'),

    },
    {
        path: '/categories/:categoryId',
        component: () => import( '@/components/ChildRouter'),
        children: [
            {
                path: 'edit',
                name: 'CategoryEdit',
                component: () => import( '@/components/Category/CategoryEdit'),
            }
        ]

    },
    // CATALOG-COLLECTIONS ROUTES
    {
        path: '/collections',
        name: 'CollectionsList',
        component: () => import( '@/components/Collection/Collections'),
    },
    {
        path: '/collections/create',
        name: 'CollectionCreate',
        component: () => import( '@/components/Collection/CollectionEdit'),
    },
    {
        path: '/collections/:collectionId',
        component: () => import( '@/components/ChildRouter'),
        children: [
            {
                path: 'edit',
                name: 'CollectionEdit',
                component: () => import( '@/components/Collection/CollectionEdit'),
            }
        ]

    },
    // FILTERS ROUTES
    {
        path: '/filters',
        name: 'FiltersList',
        component: () => import( '@/components/Filter/Filters'),
    },
    {
        path: '/filters/create',
        name: 'FilterCreate',
        component: () => import( '@/components/Filter/FilterEdit'),

    },
    {
        path: '/filters/:filterId',
        component: () => import( '@/components/ChildRouter'),
        children: [
            {
                path: 'edit',
                name: 'FilterEdit',
                component: () => import( '@/components/Filter/FilterEdit'),
            }
        ]
    },
    // FAQ CATEGORIES ROUTES
    {
        path: '/faqs-categories',
        name: 'FaqCategoriesList',
        component: () => import( '@/components/FaqCategory/FaqCategories'),
    },
    {
        path: '/faqs-categories/create',
        name: 'FaqCategoryCreate',
        component: () => import( '@/components/FaqCategory/FaqCategoryEdit'),

    },
    {
        path: '/faqs-categories/:faqCategoryId',
        component: () => import( '@/components/ChildRouter'),
        children: [
            {
                path: 'edit',
                name: 'FaqCategoryEdit',
                component: () => import( '@/components/FaqCategory/FaqCategoryEdit'),
            }
        ]

    },
    // FAQ ROUTES
    {
        path: '/faqs',
        name: 'FaqList',
        component: () => import( '@/components/Faq/Faqs'),
    },
    {
        path: '/faqs/create',
        name: 'FaqCreate',
        component: () => import( '@/components/Faq/FaqEdit'),

    },
    {
        path: '/faqs/:faqId',
        component: () => import( '@/components/ChildRouter'),
        children: [
            {
                path: 'edit',
                name: 'FaqEdit',
                component: () => import( '@/components/Faq/FaqEdit'),
            }
        ]

    },
    // FAQ REQUESTS ROUTES
    {
        path: '/faqs-requests',
        name: 'FaqRequestsList',
        component: () => import( '@/components/FaqRequest/FaqRequests'),
    },
    {
        path: '/faqs-requests/:faqRequestId',
        component: () => import( '@/components/ChildRouter'),
        children: [
            {
                path: 'view',
                name: 'FaqRequestView',
                component: () => import( '@/components/FaqRequest/FaqRequestView'),
            }
        ]

    },
    // FAQ REQUESTS ROUTES
    {
        path: '/orders',
        name: 'OrdersList',
        component: () => import( '@/components/Order/Orders'),
    },
    {
        path: '/orders/:orderId',
        component: () => import( '@/components/ChildRouter'),
        children: [
            {
                path: 'edit',
                name: 'OrderEdit',
                component: () => import( '@/components/Order/OrderEdit'),
            }
        ]

    },
    // Brand ROUTES
    {
        path: '/brands',
        name: 'BrandList',
        component: () => import( '@/components/Brand/Brands'),
    },
    {
        path: '/brands/create',
        name: 'BrandCreate',
        component: () => import( '@/components/Brand/BrandEdit'),

    },
    {
        path: '/brands/:brandId',
        component: () => import( '@/components/ChildRouter'),
        children: [
            {
                path: 'edit',
                name: 'BrandEdit',
                component: () => import( '@/components/Brand/BrandEdit'),
            }
        ]

    },
    // BLOG POSTS
    {
        path: '/blog/posts',
        name: 'BlogPosts',
        component: () => import( '@/components/BlogPost/BlogPosts'),
    },
    {
        path: '/blog/posts/create',
        name: 'BlogPostCreate',
        component: () => import( '@/components/BlogPost/BlogPostEdit'),
    },
    {
        path: '/blog/posts/:postId',
        component: () => import( '@/components/ChildRouter'),
        children: [
            {
                path: 'edit',
                name: 'BlogPostEdit',
                component: () => import( '@/components/BlogPost/BlogPostEdit'),
            }
        ]

    },
    // SERVICES ROUTES
    {
        path: '/services',
        name: 'ServiceList',
        component: () => import( '@/components/Service/Services'),
    },
    {
        path: '/services/create',
        name: 'ServiceCreate',
        component: () => import( '@/components/Service/ServiceEdit'),

    },
    {
        path: '/services/:serviceId',
        component: () => import( '@/components/ChildRouter'),
        children: [
            {
                path: 'edit',
                name: 'ServiceEdit',
                component: () => import( '@/components/Service/ServiceEdit'),
            }
        ]

    },
    // SERVICES REQUESTS ROUTES
    {
        path: '/service-requests',
        name: 'ServiceRequestsList',
        component: () => import( '@/components/ServiceRequest/ServiceRequests'),
    },
    {
        path: '/service-requests/:serviceRequestId',
        component: () => import( '@/components/ChildRouter'),
        children: [
            {
                path: 'edit',
                name: 'ServiceRequestEdit',
                component: () => import( '@/components/ServiceRequest/ServiceRequestEdit'),
            }
        ]

    },
    // STATIC PAGES ROUTES
    {
        path: '/static-pages',
        name: 'StaticPagesList',
        component: () => import( '@/components/StaticPages/StaticPages'),
    },
    {
        path: '/static-pages/create',
        name: 'StaticPageCreate',
        component: () => import( '@/components/StaticPages/StaticPageEdit'),

    },
    {
        path: '/static-pages/:staticPageId',
        component: () => import( '@/components/ChildRouter'),
        children: [
            {
                path: 'edit',
                name: 'StaticPageEdit',
                component: () => import( '@/components/StaticPages/StaticPageEdit'),
            }
        ]

    },
    // SETTINGS ROUTES
    {
        path: '/settings/socials',
        name: 'SocialList',
        component: () => import( '@/components/Social/Socials'),
    },
    {
        path: '/settings/socials/create',
        name: 'SocialCreate',
        component: () => import( '@/components/Social/SocialEdit'),

    },
    {
        path: '/settings/socials/:socialId',
        component: () => import( '@/components/ChildRouter'),
        children: [
            {
                path: 'edit',
                name: 'SocialEdit',
                component: () => import( '@/components/Social/SocialEdit'),
            }
        ]

    },
    {
        path: '/settings/our_stores',
        name: 'OurStoreList',
        component: () => import( '@/components/OurStore/OurStores'),
    },
    {
        path: '/settings/our_stores/create',
        name: 'OurStoreCreate',
        component: () => import( '@/components/OurStore/OurStoreEdit'),

    },
    {
        path: '/settings/our_stores/:storeId',
        component: () => import( '@/components/ChildRouter'),
        children: [
            {
                path: 'edit',
                name: 'OurStoreEdit',
                component: () => import( '@/components/OurStore/OurStoreEdit'),
            }
        ]

    },
    {
        path: '/settings/other',
        name: 'SettingsOther',
        component: () => import( '@/components/Settings/Other'),
    },
    // {
    //     path: '/settings/contacts',
    //     name: 'SettingContacts',
    //     component: () => import( '@/components/Settings/Contacts'),
    // },
    // PRODUCT ROUTES
    {
        path: '/products',
        name: 'ProductsList',
        component: () => import( '@/components/Product/Products'),
    },
    {
        path: '/products/create',
        name: 'ProductCreate',
        component: () => import( '@/components/Product/ProductEdit'),

    },
    {
        path: '/products/import',
        name: 'ProductsImport',
        component: () => import( '@/components/Product/ProductsImport'),

    },
    // {
    //     path: '/products/copy',
    //     name: 'ProductCopy',
    //     component: () => import( '@/components/Product/ProductCopy'),

    // },
    {
        path: '/products/:productId',
        component: () => import( '@/components/ChildRouter'),
        children: [
            {
                path: 'edit',
                name: 'ProductEdit',
                component: () => import( '@/components/Product/ProductEdit'),

            },
            {
                path: 'copy',
                name: 'ProductCopy',
                component: () => import( '@/components/Product/ProductCopy'),

            }
        ]

    },
    // CONTACTS ROUTES
    {
        path: '/contact-us',
        name: 'ContactUsList',
        component: () => import( '@/components/Contact/Contacts'),
    },
    // PROMOTIONAL ROUTES
    {
        path: '/promotional',
        name: 'PromotionalList',
        component: () => import( '@/components/Promotional/Promotionals'),
    },
    {
        path: '/promotionals/create',
        name: 'PromotionalCreate',
        component: () => import( '@/components/Promotional/PromotionalEdit'),

    },
    {
        path: '/promotionals/:promotionalId',
        component: () => import( '@/components/ChildRouter'),
        children: [
            {
                path: 'edit',
                name: 'PromotionalEdit',
                component: () => import( '@/components/Promotional/PromotionalEdit'),

            }
        ]
    },
    // SLIDER ROUTES
    {
        path: '/sliders',
        name: 'SliderList',
        component: () => import( '@/components/Slider/SliderList'),
    },
    {
        path: '/sliders/create',
        name: 'SliderCreate',
        component: () => import( '@/components/Slider/SliderEdit'),

    },
    {
        path: '/sliders/:sliderId',
        component: () => import( '@/components/ChildRouter'),
        children: [
            {
                path: 'edit',
                name: 'SliderEdit',
                component: () => import( '@/components/Slider/SliderEdit'),

            }
        ]
    },
    {
        path: '/',
        name: 'Home',
        component: () => import( '@/views/Home')
    },
    {
        path: '/login',
        name: 'Login',
        component: () => import( '@/components/Login')
    }
];