import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import { i18n } from './plugins/i18n.js'
import moment from 'moment'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import locale from 'element-ui/lib/locale/lang/ua'
import VueMask from 'vue-inputmasked'

// const VueInputMask = require('vue-inputmask')

Vue.config.productionTip = false

// elements
Vue.use(ElementUI, { locale })
Vue.use(VueMask);
// Vue.use(VueInputMask)

// validator
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import "./vee-validate";
Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);

// moment
Vue.prototype.moment = moment

// errors
Vue.prototype.$apiErrors = {}

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
