<template>
    <nav class="navbar navbar-dark fixed-top bg-dark flex-md-nowrap p-0 shadow">
      <a class="navbar-brand col-sm-3 col-md-2 mr-0" href="#">Admin</a>
      <!-- <input class="form-control form-control-dark w-100" type="text" placeholder="Search" aria-label="Search"> -->
      <ul class="navbar-nav px-3">
        <li class="nav-item text-nowrap">
          <a v-on:click="logOut()" class="nav-link">{{ $t('Logout') }}</a>
        </li>
      </ul>
    </nav>
</template>
<script>
export default {
    name: 'HeaderCompoennt',
    mounted () {
      if (this.$store.getters['auth/isAuthenticated']) {
        this.$store.dispatch('order/getNewOrdersCount')
      }
    },
    methods: {
        logOut () {
            this.$store.dispatch('auth/logOut')
        },
    }
}
</script>