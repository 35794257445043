// import axios from 'axios'
import Vue from 'vue';
import VueLocalStorage from 'vue-localstorage';
import { HTTP } from '@/api/http_utils'

// import router from '@/router/index.js'

Vue.use(VueLocalStorage);

export default {
    namespaced: true,
    state: {
        //
    },
    getters: {
        //
    },
    mutations: {
        //
    },
    actions: {
        getList ({ commit }, payload) {
            return new Promise((resolve, reject) => {
                HTTP.get(`/contacts?page=${payload['page']}&per_page=${payload['perPage']}` + payload['filters']).then(response => {
                    resolve(response)
                }).catch(error => {
                    reject(error)
                })
            })
        },
        delete ({commit}, Id) {
            return new Promise((resolve, reject) => {
                HTTP.delete('/contacts/' + Id).then(response => {
                    resolve(response)
                }).catch(error => {
                    reject(error)
                })
            })
        }
    },
  };