// import axios from 'axios'
import Vue from 'vue';
import VueLocalStorage from 'vue-localstorage';
import { HTTP } from '@/api/http_utils'

// import router from '@/router/index.js'

Vue.use(VueLocalStorage);

export default {
    namespaced: true,
    state: {
        //
    },
    getters: {
        //
    },
    mutations: {
        //
    },
    actions: {
        getList ({ commit }, data) {
            return new Promise((resolve, reject) => {
                HTTP.get(`/faqs?page=${data['page']}&per_page=${data['perPage']}`).then(response => {
                    resolve(response)
                }).catch(error => {
                    reject(error)
                })
            })
        },
        get ({ commit }, Id) {
            return new Promise((resolve, reject) => {
                HTTP.get('/faqs/' + Id).then(response => {
                    resolve(response)
                }).catch(error => {
                    reject(error)
                })
            })
        },
        create ({ commit }, payload) {
            return new Promise((resolve, reject) => {
                HTTP.post('/faqs', payload).then(response => {
                    resolve(response)
                }).catch(error => {
                    reject(error)
                })
            })
        },
        update ({ commit }, [Id, payload]) {
            return new Promise((resolve, reject) => {
                HTTP.patch('/faqs/' + Id, payload).then(response => {
                    resolve(response)
                }).catch(error => {
                    reject(error)
                })
            })
        },
        delete ({ commit }, Id) {
            return new Promise((resolve, reject) => {
                HTTP.delete('/faqs/' + Id).then(response => {
                    resolve(response)
                }).catch(error => {
                    reject(error)
                })
            })
        }
    },
  };