<template>
    <nav class="col-md-2 d-md-block bg-light sidebar">
        <div class="sidebar-sticky">
            <el-row class="">
                <el-col >
                    <el-menu
                    class="el-menu-vertical"
                    :default-active="defaultActive"
                    @open="handleOpen"
                    @close="handleClose"
                    active-text-color="#dc3545">
                    <el-menu-item index="/">
                        <i class="el-icon-menu"></i>
                        <span slot="title">{{ $t('Dashboard') }}</span>
                    </el-menu-item>
                    <el-submenu index="0">
                        <template slot="title">
                            <i class="el-icon-user"></i>
                            <span>{{ $t('Users') }}</span>
                        </template>
                        <router-link style="text-decoration: none;" :to="{ name: 'UsersList', query: { page: 1, 'page-size': 30}}" class="lost-pass">
                            <el-menu-item index="/users">
                                <i class="el-icon-s-unfold"></i>
                                <span>{{ $t('List') }}</span>
                            </el-menu-item>
                        </router-link>
                    </el-submenu>
                    <el-submenu index="1">
                        <template slot="title">
                            <i class="el-icon-phone"></i>
                            <span>{{ $t('ContactsUs') }}</span>
                        </template>
                        <router-link style="text-decoration: none;" :to="{ name: 'ContactUsList', query: { page: 1, 'page-size': 30}}" class="lost-pass">
                            <el-menu-item index="/contact-us">
                                <i class="el-icon-s-unfold"></i>
                                <span>{{ $t('Requests list') }}</span>
                            </el-menu-item>
                        </router-link>
                    </el-submenu>
                    <!-- <el-submenu index="2">
                        <template slot="title">
                            <i class="el-icon-question"></i>
                            <span>{{ $t('Faqs') }}</span>
                        </template>
                        <router-link style="text-decoration: none;" :to="{ name: 'FaqList', query: { page: 1, 'page-size': 30}}" class="lost-pass">
                            <el-menu-item index="/faqs">
                                <i class="el-icon-s-unfold"></i>
                                <span>{{ $t('List') }}</span>
                            </el-menu-item>
                        </router-link>
                        <router-link style="text-decoration: none;" :to="{ name: 'FaqRequestsList', query: { page: 1, 'page-size': 30}}" class="lost-pass">
                            <el-menu-item index="/faqs-requests">
                                <i class="el-icon-reading"></i>
                                <span>{{ $t('faqs_request') }}</span>
                            </el-menu-item>
                        </router-link>
                        <router-link style="text-decoration: none;" :to="{ name: 'FaqCategoriesList'}">
                            <el-menu-item index="/faqs-categories">
                                <i class="el-icon-setting"></i>
                                <span>{{ $t('Categories') }}</span>
                            </el-menu-item>
                        </router-link>
                    </el-submenu> -->
                    <el-submenu index="3">
                        <template slot="title">
                            <i class="el-icon-s-goods"></i>
                            <span>{{ $t('Products') }}</span>
                        </template>
                        <router-link style="text-decoration: none;" :to="{ name: 'ProductsList', query: { page: 1, 'page-size': 30} }" class="lost-pass">
                            <el-menu-item index="/products">
                                <i class="el-icon-s-unfold"></i>
                                <span>{{ $t('List') }}</span>
                                <!-- <router-link :to="{ name: 'login'}" class="lost-pass">{{ $t('Login') }}</router-link> -->
                            </el-menu-item>
                        </router-link>
                        <router-link style="text-decoration: none;" :to="{ name: 'ProductsImport' }" class="lost-pass">
                            <el-menu-item index="/products/import">
                                <i class="el-icon-download"></i>
                                <span>{{ $t('Import') }}</span>
                            </el-menu-item>
                        </router-link>
                    </el-submenu>
                    <el-submenu index="3_1">
                        <template slot="title">
                            <i class="el-icon-s-marketing"></i>
                            <span>{{ $t('Orders') }} <el-badge v-if="newOrders" :value="newOrders" /></span>
                        </template>
                        <router-link style="text-decoration: none;" :to="{ name: 'OrdersList', query: { page: 1, 'page-size': 30} }" class="lost-pass">
                            <el-menu-item index="/orders">
                                <i class="el-icon-s-unfold"></i>
                                <span>{{ $t('List') }}</span>
                                <!-- <router-link :to="{ name: 'login'}" class="lost-pass">{{ $t('Login') }}</router-link> -->
                            </el-menu-item>
                        </router-link>
                    </el-submenu>
                    <el-submenu index="4">
                        <template slot="title">
                            <i class="el-icon-s-data"></i>
                            <span>{{ $t('Catalogs') }}</span>
                        </template>
                        <router-link style="text-decoration: none;" :to="{ name: 'CatalogsList'}" class="lost-pass">
                            <el-menu-item index="/catalogs">
                                <i class="el-icon-shopping-bag-1"></i>
                                <span>{{ $t('Catalogs') }}</span>
                                <!-- <router-link :to="{ name: 'login'}" class="lost-pass">{{ $t('Login') }}</router-link> -->
                            </el-menu-item>
                        </router-link>
                        <router-link style="text-decoration: none;" :to="{ name: 'CategoriesList', query: { page: 1, 'page-size': 30}}" class="lost-pass">
                            <el-menu-item index="/categories">
                                <i class="el-icon-document-copy"></i>
                                <span>{{ $t('Categories') }}</span>
                                <!-- <router-link :to="{ name: 'login'}" class="lost-pass">{{ $t('Login') }}</router-link> -->
                            </el-menu-item>
                        </router-link>
                        <router-link style="text-decoration: none;" :to="{ name: 'FiltersList'}" class="lost-pass">
                            <el-menu-item index="/filters">
                                <i class="el-icon-sort"></i>
                                <span>{{ $t('Filters') }}</span>
                                <!-- <router-link :to="{ name: 'login'}" class="lost-pass">{{ $t('Login') }}</router-link> -->
                            </el-menu-item>
                        </router-link>
                    </el-submenu>
                    <el-submenu index="slider">
                        <template slot="title">
                            <i class="el-icon-picture-outline"></i>
                            <span>{{ $t('Slider') }}</span>
                        </template>
                        <router-link style="text-decoration: none;" :to="{ name: 'SliderList', query: { page: 1, 'page-size': 30} }" class="lost-pass">
                            <el-menu-item index="/sliders">
                                <i class="el-icon-s-unfold"></i>
                                <span>{{ $t('List') }}</span>
                                <!-- <router-link :to="{ name: 'login'}" class="lost-pass">{{ $t('Login') }}</router-link> -->
                            </el-menu-item>
                        </router-link>
                    </el-submenu>
                    <el-submenu index="promotional">
                        <template slot="title">
                            <i class="el-icon-s-finance"></i>
                            <span>{{ $t('Promotionals') }}</span>
                        </template>
                        <router-link style="text-decoration: none;" :to="{ name: 'PromotionalList', query: { page: 1, 'page-size': 30} }" class="lost-pass">
                            <el-menu-item index="/promotionals">
                                <i class="el-icon-s-unfold"></i>
                                <span>{{ $t('List') }}</span>
                                <!-- <router-link :to="{ name: 'login'}" class="lost-pass">{{ $t('Login') }}</router-link> -->
                            </el-menu-item>
                        </router-link>
                    </el-submenu>
                    <el-submenu index="5">
                        <template slot="title">
                            <i class="el-icon-notebook-1"></i>
                            <span>{{ $t('Static pages') }}</span>
                        </template>
                        <router-link style="text-decoration: none;" :to="{ name: 'StaticPagesList'}" class="lost-pass">
                            <el-menu-item index="/static-pages">
                                <i class="el-icon-s-unfold"></i>
                                <span>{{ $t('List') }}</span>
                            </el-menu-item>
                        </router-link>
                    </el-submenu>
                    <el-submenu index="6">
                        <template slot="title">
                            <i class="el-icon-s-tools"></i>
                            <span>{{ $t('Settings') }}</span>
                        </template>
                        <router-link style="text-decoration: none;" :to="{ name: 'SocialList'}">
                            <el-menu-item index="/settings/socials">
                                <i class="el-icon-s-custom"></i>
                                <span>{{ $t('social_sidebar') }}</span>
                            </el-menu-item>
                        </router-link>
                        <router-link style="text-decoration: none;" :to="{ name: 'OurStoreList'}">
                            <el-menu-item index="/settings/our_stores">
                                <i class="el-icon-school"></i>
                                <span>{{ $t('Our stores') }}</span>
                            </el-menu-item>
                        </router-link>
                        <router-link style="text-decoration: none;" :to="{ name: 'SettingsOther'}">
                            <el-menu-item index="/settings/other">
                                <i class="el-icon-setting"></i>
                                <span>{{ $t('Other settings') }}</span>
                            </el-menu-item>
                        </router-link>
                    </el-submenu>
                    <el-submenu index="7">
                        <template slot="title">
                            <i class="el-icon-service"></i>
                            <span>{{ $t('Services') }}</span>
                        </template>
                        <router-link style="text-decoration: none;" :to="{ name: 'ServiceList'}">
                            <el-menu-item index="/services">
                                <i class="el-icon-s-unfold"></i>
                                <span>{{ $t('List') }}</span>
                                <!-- <router-link :to="{ name: 'login'}" class="lost-pass">{{ $t('Login') }}</router-link> -->
                            </el-menu-item>
                        </router-link>
                        <router-link style="text-decoration: none;" :to="{ name: 'ServiceRequestsList', query: { page: 1, 'page-size': 30}}">
                            <el-menu-item index="/service-requests">
                                <i class="el-icon-s-unfold"></i>
                                <span>{{ $t('Requests list') }}</span>
                            </el-menu-item>
                        </router-link>
                    </el-submenu>
                    <!-- <el-submenu index="8">
                        <template slot="title">
                            <i class="el-icon-coordinate"></i>
                            <span>{{ $t('Brands') }}</span>
                        </template>
                        <router-link style="text-decoration: none;" :to="{ name: 'BrandList'}">
                            <el-menu-item index="/brands">
                                <i class="el-icon-s-unfold"></i>
                                <span>{{ $t('List') }}</span>
                            </el-menu-item>
                        </router-link>
                    </el-submenu> -->
                    <el-submenu index="9">
                        <template slot="title">
                            <i class="el-icon-coordinate"></i>
                            <span>{{ $t('Blog') }}</span>
                        </template>
                        <router-link style="text-decoration: none;" :to="{ name: 'BlogPosts'}">
                            <el-menu-item index="/blog/posts">
                                <i class="el-icon-s-unfold"></i>
                                <span>{{ $t('Posts list') }}</span>
                                <!-- <router-link :to="{ name: 'login'}" class="lost-pass">{{ $t('Login') }}</router-link> -->
                            </el-menu-item>
                        </router-link>
                    </el-submenu>
                    <!-- <el-submenu index="10">
                        <template slot="title">
                            <i class="el-icon-service"></i>
                            <span>{{ $t('Promotionals') }}</span>
                        </template>
                        <router-link style="text-decoration: none;" :to="{ name: 'PromotionalsList'}">
                            <el-menu-item index="/promotionals">
                                <i class="el-icon-s-unfold"></i>
                                <span>{{ $t('List') }}</span>
                            </el-menu-item>
                        </router-link>
                    </el-submenu> -->
                    </el-menu>
                </el-col>
            </el-row>
        </div>
    </nav>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
data () {
    return {
        defaultActive: this.$route.path,
        // newOrders: 0
    }
},
computed: {
    ...mapGetters({
        newOrders: 'order/newOrders'
    })
},
watch: {
    $route(to, from) {
        if (to.path !== from.path) {
            this.defaultActive = to.path
        }
    }
},
methods: {
    handleOpen(key, keyPath) {
        console.log(key, keyPath);
    },
    handleClose(key, keyPath) {
        console.log(key, keyPath);
    }
}
}
</script>