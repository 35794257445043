import Vue from 'vue';
import VueLocalStorage from 'vue-localstorage';
import { HTTP } from '@/api/http_utils'

Vue.use(VueLocalStorage);

export default {
    namespaced: true,
    state: {
        newOrders: 0
    },
    getters: {
        newOrders: state => state.newOrders,
    },
    mutations: {
        SET_NEW_ORDERS_COUNT (state, data) {
            state.newOrders = data
        }
    },
    actions: {
        getList ({ commit }, data) {
            return new Promise((resolve, reject) => {
                HTTP.get(`/orders?page=${data['page']}&per_page=${data['perPage']}`).then(response => {
                    resolve(response)
                }).catch(error => {
                    reject(error)
                })
            })
        },
        get ({ commit }, Id) {
            return new Promise((resolve, reject) => {
                HTTP.get('/orders/' + Id).then(response => {
                    resolve(response)
                }).catch(error => {
                    reject(error)
                })
            })
        },
        getNewOrdersCount ({ commit }, Id) {
            return new Promise((resolve, reject) => {
                HTTP.get('/orders?status=new').then(response => {
                    commit('SET_NEW_ORDERS_COUNT', response)
                    resolve(response)
                }).catch(error => {
                    reject(error)
                })
            })
        },
        // create ({ commit }, payload) {
        //     return new Promise((resolve, reject) => {
        //         HTTP.post('/orders', payload).then(response => {
        //             resolve(response)
        //         }).catch(error => {
        //             reject(error)
        //         })
        //     })
        // },
        update ({ commit }, [Id, payload]) {
            return new Promise((resolve, reject) => {
                HTTP.patch('/orders/' + Id, payload).then(response => {
                    resolve(response)
                }).catch(error => {
                    reject(error)
                })
            })
        },
        delete ({ commit }, Id) {
            return new Promise((resolve, reject) => {
                HTTP.delete('/orders/' + Id).then(response => {
                    resolve(response)
                }).catch(error => {
                    reject(error)
                })
            })
        }
    },
  };