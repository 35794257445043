// import axios from 'axios'
import Vue from 'vue';
import VueLocalStorage from 'vue-localstorage';
import { HTTP } from '@/api/http_utils'

// import router from '@/router/index.js'

Vue.use(VueLocalStorage);

export default {
    namespaced: true,
    state: {
        catalogs: {}
    },
    getters: {
        catalogs: state => state.catalogs,
    },
    mutations: {
        SET_CATALOGS (state, data) {
            state.catalogs = data
        }
    },
    actions: {
        getList ({ commit }, data) {
            return new Promise((resolve, reject) => {
                HTTP.get('/catalogs').then(response => {
                    commit('SET_CATALOGS', response)
                    resolve(response)
                }).catch(error => {
                    reject(error)
                })
            })
        },
        getCatalog ({ commit }, data) {
            return new Promise((resolve, reject) => {
                HTTP.get('/catalogs/' + data['catalogId']).then(response => {
                    resolve(response)
                }).catch(error => {
                    reject(error)
                })
            })
        },
        createCatalog ({ commit }, payload) {
            return new Promise((resolve, reject) => {
                HTTP.post('/catalogs', payload).then(response => {
                    resolve(response)
                }).catch(error => {
                    reject(error)
                })
            })
        },
        updateCatalog ({ commit }, [catalogId, payload]) {
            return new Promise((resolve, reject) => {
                HTTP.patch('/catalogs/' + catalogId, payload).then(response => {
                    resolve(response)
                }).catch(error => {
                    reject(error)
                })
            })
        },
        attach ({ commit }, [Id, payload]) {
            return new Promise((resolve, reject) => {
                HTTP.post('/catalogs/' + Id + '/attach', payload).then(response => {
                    resolve(response)
                }).catch(error => {
                    reject(error)
                })
            })
        },
        deleteCatalog ({ commit }, catalogId) {
            return new Promise((resolve, reject) => {
                HTTP.delete('/catalogs/' + catalogId).then(response => {
                    resolve(response)
                }).catch(error => {
                    reject(error)
                })
            })
        }
    },
  };