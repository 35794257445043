import Vue from 'vue'
import Vuex from 'vuex'

import { HTTP } from '@/api/http_utils'

import auth from './modules/auth';
import language from './modules/language';
import contactUs from './modules/contactUs';
import catalog from './modules/catalog';
import category from './modules/category';
import product from './modules/product';
import service from './modules/service';
import filter from './modules/filter';
import faq from './modules/faq';
import faq_category from './modules/faq_category';
import faq_request from './modules/faq_request';
import static_page from './modules/static_page';
import settings from './modules/settings';
import social from './modules/social';
import brand from './modules/brand';
import our_store from './modules/our_store';
import image from './modules/image';
import blog_post from './modules/blog_post';
import user from './modules/user';
import order from './modules/order';
import service_request from './modules/service_request';
import collection from './modules/collection';
import slider from './modules/slider';
import promotional from './modules/promotional';

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    apiErrors: {},
  },
  getters: {
    apiErrors: state => state.apiErrors
  },
  mutations: {
    API_ERRORS (state, error) {
      state.apiErrors = {}
      state.apiErrors = error
    }
  },
  actions: {
    getErrors ({ commit }, apiErrors) {
      commit('API_ERRORS', apiErrors)
    },
    getCountries ({ commit }) {
      return new Promise((resolve, reject) => {
          HTTP.get('/countries').then(response => {
              resolve(response)
          }).catch(error => {
              reject(error)
          })
      })
    }
  },
  modules: {
    auth,
    language,
    contactUs,
    catalog,
    category,
    product,
    service,
    promotional,
    filter,
    faq,
    faq_category,
    static_page,
    settings,
    social,
    image,
    brand,
    our_store,
    blog_post,
    faq_request,
    user,
    order,
    service_request,
    collection,
    slider
  }
})
