import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store';
import routes from './routes';

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  // console.log(to['name'], from['name']);
  if (!store.getters['auth/isAuthenticated']) {
    if (to['path'] !== '/login') {
      next('/login')
    }
  }
  next()
});

export default router
