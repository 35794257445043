import { extend, configure } from "vee-validate";
import * as rules from "vee-validate/dist/rules";
import { i18n } from "@/plugins/i18n";
import isURL from 'validator/lib/isURL';
import PhoneNumber from 'awesome-phonenumber'

configure({
  defaultMessage: (field, values) => {
    // override the field name.
    values._field_ = i18n.t(`fields.${field}`);

    return i18n.t(`validation.${values._rule_}`, values);
  }
});

extend("decimal", {
  validate: (value, { decimals = '*', separator = '.' } = {}) => {
    if (value === null || value === undefined || value === '') {
      return {
        valid: false
      };
    }
    if (Number(decimals) === 0) {
      return {
        valid: /^-?\d*$/.test(value),
      };
    }
    const regexPart = decimals === '*' ? '+' : `{1,${decimals}}`;
    const regex = new RegExp(`^[-+]?\\d*(\\${separator}\\d${regexPart})?([eE]{1}[-]?\\d+)?$`);
    return {
      valid: regex.test(value),
      data: {
        serverMessage: 'Only decimal values are available'
      }
    };
  },
  message: `{serverMessage}`
})


extend("phone", {
  // params: ["otherValue", "maxDifference"],
  // validate: (value, { otherValue, maxDifference }) => {
  validate: (value) => {
    let phone = new PhoneNumber(value)
    // console.log(phone)
    if (phone.isValid()) {
      return true;
    }
    return false;
  },
  message:
  i18n.t("This value must be phone")
});

extend("url", {
  // params: ["otherValue", "maxDifference"],
  // validate: (value, { otherValue, maxDifference }) => {
  validate: (value) => {
    if (isURL(value, {require_tld: true, require_protocol: true, protocols: ['http','https','ftp']})) {
      return true;
    }
    return false;
  },
  message:
  i18n.t("This value must be url")
});

Object.keys(rules).forEach(rule => {
  extend(rule, {
    ...rules[rule], // copies rule configuration
    // message: messages[rule] // assign message
  });
});
